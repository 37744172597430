<template>
  <div id="app">
    <div class="container">
      <div class="outer-nav--return"></div>
      <CookieLgpd />
      <div id="viewport" class="l-viewport">
        <div class="l-wrapper">
          <Header />
          <MenuLine />
          <router-view />
        </div>
      </div>
    </div>
    <Menu />
    <Footer />
  </div>
</template>



<script>
import Footer from "./components/Footer.vue";
import Clientes from "./components/sections/Clientes.vue";
import Contato from "./components/sections/Contato.vue";
import Familia from "./components/sections/Familia.vue";
import Home from "./components/sections/Home.vue";
import Parceiros from "./components/sections/Parceiros.vue";
import Sistemas from "./components/sections/Sistemas.vue";
import Sobre from "./components/sections/Sobre.vue";
import TrabalheConosco from "./components/sections/TrabalheConosco.vue";
import MenuLine from "./components/MenuLine.vue";
import Menu from "./components/Menu.vue";
import Header from "./components/Header.vue";
import CookieLgpd from "@/components/CookieLgpd.vue";
import Lgpd from "./components/sections/Lgpd.vue";
import PortalLgpd from "./components/PortalLgpd.vue";

export default {
  name: "App",
  components: {
    Footer,
    Clientes,
    Contato,
    Familia,
    Home,
    Parceiros,
    Sistemas,
    Sobre,
    TrabalheConosco,
    Menu,
    MenuLine,
    Header,
    Lgpd,
    PortalLgpd,
    CookieLgpd,
  },
};
</script>
